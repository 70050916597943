import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';

import { If } from 'utils';
import { NotificationsBanner } from 'components';
import BannerCarousel from '../BannerCarousel';
import { NOTIFICATION_BANNER_CONTAINER_ID } from 'components/NotificationsBanner';
import { HeaderContext } from 'context/HeaderContext';
import {
  checkIfElementRemovedFromDom,
  waitForElementToRenderOnDom
} from '../../methods';

export default function Banner() {
  const [showBanners, setShowBanners] = useState(false);
  const { isMounted: isCustomHeaderMounted } = useContext(HeaderContext);

  const checkIfPortalContainerLoaded = useCallback(async () => {
    const elm = await waitForElementToRenderOnDom(
      NOTIFICATION_BANNER_CONTAINER_ID
    );
    if (elm) {
      setShowBanners(true);
    }
  }, []);

  useEffect(() => {
    if (!showBanners) {
      checkIfPortalContainerLoaded();
    }
  }, [checkIfPortalContainerLoaded, showBanners]);

  useEffect(() => {
    const checkIfPortalContainerUnLoaded = async () => {
      const id = await checkIfElementRemovedFromDom(
        NOTIFICATION_BANNER_CONTAINER_ID
      );
      if (id === NOTIFICATION_BANNER_CONTAINER_ID) {
        setShowBanners(false);
      }
    };

    if (showBanners) {
      checkIfPortalContainerUnLoaded();
    }
  }, [showBanners]);

  return (
    <If test={showBanners && !isCustomHeaderMounted}>
      <NotificationsBanner>
        <div className={styles.customCarousel}>
          <BannerCarousel />
        </div>
      </NotificationsBanner>
    </If>
  );
}
