import posthog from 'posthog-js';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
// import * as mixpanel from 'mixpanel-browser';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

import { logError } from 'utils';
import { preventErrorLog } from 'utils/methods';
import localStore from '../localStore.js';

export default function identifyUser() {
  const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;
  const posthogApp = process.env.REACT_APP_POSTHOG_KEY;
  const sentryApp = process.env.REACT_APP_SENTRY_APP_ID;
  // const mixpanelApp = process.env.REACT_APP_MIXPANEL_ID;

  try {
    const userData = localStore.get('userData');
    const store = localStore.get('rrloggedInStoreName');

    if (!userData || !_isString(userData) || preventErrorLog()) {
      return;
    }

    const user = JSON.parse(userData);
    const userId = _get(user, 'userId', '');
    const email = _get(user, 'email', '');
    const firstName = _get(user, 'firstName', '');
    const lastName = _get(user, 'lastName', '');
    const name = `${firstName} ${lastName}`;

    if (!userId) {
      return;
    }

    // if (mixpanelApp) {
    // const userProperties = {
    //   $first_name: firstName,
    //   $last_name: lastName
    // };
    // mixpanel.identify(userId);
    // mixpanel.people.set(userProperties);
    // }

    if (posthogApp) {
      posthog.identify(userId, { name, email, store });
      posthog.group('store', store);
    }

    if (logRocketApp) {
      LogRocket.identify(userId, { name, email, store });
    }

    if (sentryApp) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userId, email, username: name });
      });
    }
  } catch (e) {
    logError(e);
  }
}
