import { useCallback, useEffect, useRef, useState } from 'react';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import _isFunction from 'lodash/isFunction';

import { get, post, logError } from 'utils';
import { CustomToast } from 'components';

const DEFAULT = {
  method: 'GET',
  loadOnMount: true,
  initialData: null,
  isPublicAPI: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  cancelToken: null,
  axiosConfig: {},
  onTransform: data => data,
  onSuccess: () => {},
  onError: () => {},
  onFinally: () => {},
  errorMessage: 'Unable to fetch data at the moment. Please try again later.'
};

const API_METHODS = {
  GET: () => get,
  POST: () => post
};

/**
 * useFetch arguments
 * @param {string} apiKey API Key map from the API.js [API_ENDPOINTS]
 * @param {import("utils/types").FetchConfig} [config] Hook configuration
 */
export default function useFetch(apiKey, config) {
  const method = _get(config, 'method', DEFAULT.method);

  if (!apiKey) {
    throw new Error('API Key is required in the useFetch hook');
  } else if (!(method in API_METHODS)) {
    throw new Error(`"${method}" is not valid api method`);
  }

  const initialData = _get(config, 'initialData', DEFAULT.initialData);
  const loadOnMount = _get(config, 'loadOnMount', DEFAULT.loadOnMount);
  const axiosConfig = _get(config, 'axiosConfig', DEFAULT.axiosConfig);
  const payload = _get(config, 'payload', DEFAULT.payload);
  const routeParam = _get(config, 'routeParam', DEFAULT.routeParam);
  const routeParams = _get(config, 'routeParams', DEFAULT.routeParams);
  const cancelToken = _get(config, 'cancelToken', DEFAULT.cancelToken);
  const isPublicAPI = _get(config, 'isPublicAPI', DEFAULT.isPublicAPI);
  const onTransform = _get(config, 'onTransform', DEFAULT.onTransform);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onError = _get(config, 'onError', DEFAULT.onError);
  const onFinally = _get(config, 'onFinally', DEFAULT.onFinally);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);

  const [data, setData] = useState(initialData);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(loadOnMount);

  const isMountedRef = useRef(false);
  const payloadRef = useRef(payload);
  const onTransformCallbackRef = useRef(onTransform);
  const onSuccessCallbackRef = useRef(onSuccess);
  const onErrorCallbackRef = useRef(onError);
  const onFinallyCallbackRef = useRef(onFinally);

  useEffect(() => {
    payloadRef.current = payload;
    onTransformCallbackRef.current = onTransform;
    onSuccessCallbackRef.current = onSuccess;
    onErrorCallbackRef.current = onError;
    onFinallyCallbackRef.current = onFinally;
  });

  const fetchData = useCallback(
    /**
     * @param {Record<string, *>} payloadOverrides
     * @param {import('axios').AxiosRequestConfig} axiosConfigOverrides
     */
    (payloadOverrides = {}, axiosConfigOverrides = {}) => {
      setIsError(false);
      setIsLoading(true);
      const methodGen = _get(API_METHODS, method);
      const request = methodGen();
      const payload = _isFunction(payloadRef.current)
        ? payloadRef.current()
        : payloadRef.current;

      request(
        { apiKey, noTokenRequired: isPublicAPI },
        {
          params: { routeParam, routeParams, ...payload, ...payloadOverrides },
          cancelToken,
          config: _merge({}, axiosConfig, axiosConfigOverrides)
        }
      )
        .then(res => {
          const data = onTransformCallbackRef.current(_cloneDeep(res.data));
          setData(data);
          onSuccessCallbackRef.current(data, res.data);
        })
        .catch(error => {
          setIsError(true);
          onErrorCallbackRef.current(error, _get(error, 'response.data.data'));
          const isNotified = _get(error, 'notified', false);
          const errorLog = new Error(error);
          errorLog.message = errorMessage;
          logError(errorLog);
          if (errorMessage && !isNotified) {
            CustomToast({ type: 'error', msg: errorMessage });
          }
        })
        .finally(() => {
          setIsLoading(false);
          onFinallyCallbackRef.current();
        });
    },
    [
      apiKey,
      method,
      isPublicAPI,
      routeParam,
      routeParams,
      cancelToken,
      axiosConfig,
      errorMessage
    ]
  );

  useEffect(() => {
    if (!isMountedRef.current && loadOnMount) {
      isMountedRef.current = true;
      fetchData();
    }
  }, [fetchData, loadOnMount]);

  return { data, isLoading, isError, fetchData };
}
