import { useCallback, useEffect, useRef, useState } from 'react';
import { parallel } from 'async';
import axios from 'axios';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';

import { get, logError, post } from 'utils';
import { CustomToast } from 'components';

const API_METHODS = {
  GET: () => get,
  POST: () => post
};

const DEFAULT = {
  method: 'GET',
  loadOnMount: true,
  initialData: null,
  isPublicAPI: false,
  payload: {},
  routeParam: '',
  routeParams: {},
  axiosConfig: {},
  onTransform: data => data,
  onSuccess: () => {},
  onError: () => {},
  ignoreReadModeCheck: false,
  errorMessage: 'Unable to fetch data at the moment. Please try again later.'
};

/**
 * Arguments of useParallelFetch hook
 * @param {import('utils/types').ParallelApi[]} apis List of the objects defining the apiKey and the request configuration
 * @param {import('utils/types').ParallelFetchConfig} config Optional parameters to control the behaviour of the hook
 */
export default function useParallelFetch(apis = [], config = {}) {
  if (!_isArray(apis)) {
    throw new Error('"apis" must be an Array in the useParallelFetch hook');
  }

  const initialData = _get(config, 'initialData', DEFAULT.initialData);
  const loadOnMount = _get(config, 'loadOnMount', DEFAULT.loadOnMount);
  const errorMessage = _get(config, 'errorMessage', DEFAULT.errorMessage);
  const onTransform = _get(config, 'onTransform', DEFAULT.onTransform);
  const onSuccess = _get(config, 'onSuccess', DEFAULT.onSuccess);
  const onError = _get(config, 'onError', DEFAULT.onError);

  const [data, setData] = useState(initialData);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(loadOnMount && !_isEmpty(apis));

  const isMountedRef = useRef(false);
  const apisRef = useRef(apis);
  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);
  const onTransformRef = useRef(onTransform);
  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    apisRef.current = apis;
    onSuccessRef.current = onSuccess;
    onErrorRef.current = onError;
    onTransformRef.current = onTransform;
  });

  /**
   * @param {Object} payloadOverrides
   */
  const fetchData = useCallback(
    (payloadOverrides = {}) => {
      const requests = createRequests(
        apisRef.current,
        payloadOverrides,
        cancelTokenSourceRef
      );

      if (_isEmpty(requests)) return;

      setIsError(false);
      setIsLoading(true);
      parallel(requests, (error, data) => {
        if (axios.isCancel(error)) return;

        setIsLoading(false);
        if (error) {
          setIsError(true);
          onErrorRef.current(error, _get(error, 'response.data.data', null));
          const isNotified = _get(error, 'notified', false);
          const errorLog = new Error(error);
          errorLog.message = errorMessage;
          logError(errorLog);
          if (errorMessage && !isNotified) {
            CustomToast({ type: 'error', msg: errorMessage });
          }
        } else {
          const transformedData = onTransformRef.current(_cloneDeep(data));
          setData(transformedData);
          onSuccessRef.current(transformedData, data);
        }
      });
    },
    [errorMessage]
  );

  useEffect(() => {
    if (!isMountedRef.current && loadOnMount) {
      isMountedRef.current = true;
      fetchData();
    }
  }, [fetchData, loadOnMount]);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
    };
  }, []);

  return {
    data,
    isError,
    isLoading,
    fetchData
  };
}

// ----------------------------------------------------------------------------------

function createRequests(
  apis = [],
  payloadOverrides = {},
  cancelTokenSourceRef = {}
) {
  if (cancelTokenSourceRef.current) {
    cancelTokenSourceRef.current.cancel();
  }

  cancelTokenSourceRef.current = axios.CancelToken.source();

  return apis.reduce((acc, { apiKey, dataKey, ...metadata } = {}) => {
    if (!apiKey || !dataKey) {
      throw new Error(
        'apiKey and the dataKey are required in "apis" config of the useParallelFetch hook'
      );
    }

    const apiVerb = _get(metadata, 'method', DEFAULT.method);
    const payload = _get(metadata, 'payload', DEFAULT.payload);
    const axiosConfig = _get(metadata, 'axiosConfig', DEFAULT.axiosConfig);
    const routeParam = _get(metadata, 'routeParam', DEFAULT.routeParam);
    const routeParams = _get(metadata, 'routeParams', DEFAULT.routeParams);
    const isPublicAPI = _get(metadata, 'isPublicAPI', DEFAULT.isPublicAPI);
    const onTransform = _get(metadata, 'onTransform', DEFAULT.onTransform);
    const ignoreReadModeCheck = _get(
      metadata,
      'ignoreReadModeCheck',
      DEFAULT.ignoreReadModeCheck
    );
    const overridePayload = _get(payloadOverrides, dataKey, {});

    const methodGen = _get(API_METHODS, apiVerb, API_METHODS.GET);
    const apiMethod = methodGen();

    acc[dataKey] = (callback = () => {}) => {
      apiMethod(
        { apiKey, noTokenRequired: isPublicAPI, ignoreReadModeCheck },
        {
          params: {
            routeParam,
            routeParams,
            ...payload,
            ...overridePayload
          },
          config: {
            ...axiosConfig,
            cancelToken: cancelTokenSourceRef.current.token
          }
        }
      )
        .then(({ data }) => callback(null, onTransform(data)))
        .catch(err => callback(err, null));
    };

    return acc;
  }, {});
}
