import posthog from 'posthog-js';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
// import * as mixpanel from 'mixpanel-browser';

import { identifyUser } from 'utils/analytics';
import { preventErrorLog } from 'utils/methods';

export default function debugSetup() {
  if (preventErrorLog()) return;

  const environment = process.env.REACT_APP_MODE;
  const sentryApp = process.env.REACT_APP_SENTRY_APP_ID;
  const posthogApp = process.env.REACT_APP_POSTHOG_KEY;
  const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;
  // const mixpanelApp = process.env.REACT_APP_MIXPANEL_ID;

  if (sentryApp) {
    Sentry.init({
      dsn: sentryApp,
      environment,
      release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_CURRENT_GIT_SHA}`
    });
  }

  if (logRocketApp) {
    LogRocket.init(logRocketApp, {
      console: {
        // isEnabled: false,
        shouldAggregateConsoleErrors: true
      },
      release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_CURRENT_GIT_SHA}`
    });
  }

  if (posthogApp) {
    posthog.init(posthogApp, {
      api_host: process.env.REACT_APP_POSTHOG_HOST,
      disable_session_recording: environment !== 'PRODUCTION'
    });
  }

  // if (mixpanelApp) {
  //   mixpanel.init(mixpanelApp);
  // }

  identifyUser();
}
