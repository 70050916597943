import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import { post, logError } from 'utils';

export default function getProducts(
  payload = {}, // { product_ids: [], catalogue_type: "exchanges" | "purchases" | null }
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  const ids = _get(payload, 'product_ids');
  const isValid = _isArray(ids) && !_isEmpty(ids);
  if (!isValid) return onSuccess([]);

  post({ apiKey: 'products.post' }, { params: payload })
    .then(({ data }) => {
      const products = _isArray(data) ? data : [];
      onSuccess(products);
    })
    .catch(error => {
      logError(
        new Error({
          error,
          meta: {
            payload,
            errorMsg: 'fetching products failed'
          }
        })
      );
      onError(error);
    })
    .finally(onFinally);
}
