import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import createRulesOptions from './createRulesOptions';
import createReasonOptions from './createReasonOptions';
import { ENDPOINTS } from '../stubs';

/**
 * @param {import("../types").RecommendationType} type
 * @param {string} id
 * @returns {import("utils/types").SeriesApi[]}
 */
export function getSeriesAPIs(type, id) {
  if (type === 'exchange' && !id) return [];

  return [
    {
      dataKey: 'recommendation',
      apiKey: _get(ENDPOINTS[type], 'get'),
      routeParam: id
    },
    {
      dataKey: 'products',
      apiKey: 'products.post',
      method: 'POST',
      payload: ({ recommendation }) => {
        const rules = _get(recommendation, 'rules', []);
        const productIds = rules.reduce((acc, rule) => {
          const productId = _get(rule, 'product_id', '');
          if (productId) acc.push(productId);
          return acc;
        }, []);
        return {
          product_ids: productIds,
          catalogue_type:
            type === 'exchange'
              ? 'exchanges'
              : type === 'purchase'
              ? 'purchases'
              : null
        };
      },
      onTransform: products => (_isArray(products) ? products : []),
      onSkipRequest: ({ recommendation }) => {
        const rules = _get(recommendation, 'rules', []);
        const hasProductId = rules.some(rule => {
          const productId = _get(rule, 'product_id', '');
          return !!productId;
        }, []);
        return !hasProductId;
      }
    }
  ];
}

/**
 * @param {string} id
 * @param {import("../types").RecommendationType} type
 * @returns {import("utils/types").ParallelApi[]}
 */
export function getParallelAPIs(type, rulesAccessMap) {
  /** @type {import("utils/types").ParallelApi} */
  const rulesAPI = {
    dataKey: 'rules',
    apiKey: _get(ENDPOINTS[type], 'rules.get'),
    onTransform: rules => createRulesOptions(rules, rulesAccessMap)
  };

  /** @type {import("utils/types").ParallelApi} */
  const reasonsAPI = {
    dataKey: 'reasons',
    apiKey: _get(ENDPOINTS[type], 'reasons.get'),
    onTransform: reasons => createReasonOptions(reasons)
  };

  if (type === 'exchange') {
    return [rulesAPI, reasonsAPI];
  } else if (type === 'purchase') {
    return [rulesAPI];
  }
  return [];
}

/**
 * @param {string} id
 * @param {import("../types").RecommendationType} type
 * @returns {{
 * mutationApiKey: string
 * mutationMethod: 'POST' | 'PUT'
 * }}
 */
export function getMutationApi(id, type) {
  const root = ENDPOINTS[type];
  const isPurchase = type === 'purchase';

  return {
    mutationApiKey: isPurchase ? root.put : id ? root.put : root.post,
    mutationMethod: isPurchase ? 'PUT' : id ? 'PUT' : 'POST'
  };
}
